import React, { useState } from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { Card,
         CardContent,
         CardActions,
         FormControl,
         FormControlLabel,
         Radio,
         RadioGroup,
         Typography } from "@material-ui/core/"

const useStyles = makeStyles((theme) => ({
    root:{
        minHeight:"150px",
        margin:"5px"
    }
  }))

export default function QuestionCard(props) {
const {question, 
       handleChange,
       answer, 
       choices} = props
const classes = useStyles()
return ( 
  <Card className={classes.root}>
      <CardContent>
          <Typography variant="body1">
              <strong>{question}</strong>
          </Typography>
      </CardContent>
      <CardActions>
          <FormControl component="fieldset" onChange={handleChange}>
              <RadioGroup>
                  {choices.map((choice)=>(
                    <FormControlLabel key={choice.value} value={choice.value} control={<Radio checked={choice.value === answer} disabled={choice.disabled || false}/>} label={choice.label}/>
                  ))}
              </RadioGroup>
          </FormControl>
      </CardActions>
  </Card>
  )
}

